import { mapGetters } from "vuex";
import store from "@/store";

export default {
  data() {
    return {
      filledByUserTypeMap: {
        SENDER: ["ADMIN"],
        RECEIVER: ["EMPLOYEE"],
        SENDER_OR_RECEIVER: ["EMPLOYEE", "ADMIN"],
      },
      guestRoutes: [
        "GuestFormbuilderExecute",
        "GuestFormbuilderExecuteStep",
        "GuestFormbuilderExecuteUpdateStep",
        "GuestFormbuilderExecuteViewStep",
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getUserType"]),
    userType() {
      // console.log(this.getUserType)
      return this.getUserType ? this.getUserType.name : null;
    },
    isLoggedUserAdmin() {
      this.userType == "ADMIN";
    },
    getFieldColor() {
      return (property) => {
        if (
          property &&
          this.colorFields &&
          this.colorFields[this.data.key] &&
          this.colorFields[this.data.key][property]
        ) {
          return this.colorFields[this.data.key][property];
        }
        return null;
      };
    },
    getLabelStyles() {
      return this.getFieldColor("FONT_COLOR")
        ? ` color: ${this.getFieldColor("FONT_COLOR")};`
        : this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "font-weight: 700";
    },
    getElementStyle() {
      let borderStyle = "";
      let type =
        this.data.styles && this.data.styles.border_type
          ? this.data.styles.border_type
          : "";
      let size =
        this.data.styles && this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
      let color =
        this.data.styles && this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
      let font_size =
        this.data.styles &&
        this.data.styles.font &&
        this.data.styles.font.font_size
          ? this.data.styles.font.font_size
          : 14;
      let bold =
        this.data.styles &&
        this.data.styles.font &&
        (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
          ? "bold"
          : "";
      let italic =
        this.data.styles &&
        this.data.styles.font &&
        (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
          ? "italic"
          : "";
      if (type) {
        borderStyle = "border:" + type + " " + " " + size + " " + color;
        borderStyle += ";";
      }

      borderStyle +=
        this.colorFields && this.getFieldColor("BACKGROUND_COLOR")
          ? `background: ${this.getFieldColor("BACKGROUND_COLOR")};`
          : this.data.styles && this.data.styles.background
          ? `background: ${this.data.styles.background};`
          : "";
      if (font_size) {
        borderStyle += "font-size:" + (this.fromEntityViews ? ((800/1200) * font_size) : font_size) + "px;";
      }
      if (bold) {
        borderStyle += "font-weight:" + bold + ";";
      }
      if (italic) {
        borderStyle += "font-style:" + italic + ";";
      }
      borderStyle +=
        this.colorFields && this.getFieldColor("FONT_COLOR")
          ? `color: ${this.getFieldColor("FONT_COLOR")};`
          : this.data.styles && this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      return borderStyle;
    },
  },
  methods: {
    checkNumberIsEmpty(value, field) {
      let percentage =
        field?.inputType == "NUMBER" &&
        field?.properties?.number_type === "PERCENTAGE"
          ? true
          : false;
      return (
        (!value &&
          typeof value !== "number" &&
          value != 0 &&
          value !== "0" &&
          !percentage) ||
        (percentage && !value)
      );
    },
    checkDatatableDatasEqual(arg1, arg2) {
      if (Array.isArray(arg1) && Array.isArray(arg2)) {
        if (arg1.length !== arg2.length) {
          return false;
        }
        let mathched = true;
        for (let i = 0; i < arg1.length; i++) {
          if (!arg1[i].newlyAddedData && mathched) {
            let mathchedOne = arg2.find(
              (e) => e.keyIndex == arg1[i].keyIndex && !e.newlyAddedData
            );
            if (!mathchedOne || !this.deepEqual(mathchedOne, arg1[i])) {
              mathched = false;
            }
          }
        }
        return mathched;
      } else {
        return arg1 === arg2;
      }
    },
    deepEqual(obj1, obj2) {
      // If both objects are null or undefined, they are considered equal
      if (obj1 == obj2) {
        return true;
      }
      if (typeof obj1 !== "object" && typeof obj2 !== "object") {
        return obj1 === obj2;
      }
      if (!obj1 && !obj2) {
        return true;
      }
      // Check if both objects are objects and not null
      // if (
      //   typeof obj1 !== "object" ||
      //   obj1 === null ||
      //   typeof obj2 !== "object" ||
      //   obj2 === null
      // ) {
      //   return false;
      // }

      // Get the keys of the objects
      const keys1 = Object.keys(obj1 || {});
      const keys2 = Object.keys(obj2 || {});

      // If the number of keys is different, objects are not equal
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Check each property recursively
      for (let key of keys1) {
        if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }

      // If all properties are equal, objects are considered equal
      return true;
    },
    //check values
    areArgumentsEqual(arg1, arg2) {
      if (Array.isArray(arg1) && Array.isArray(arg2)) {
        if (arg1.length !== arg2.length) {
          return false;
        }

        const sortedArg1 = arg1.slice().sort();
        const sortedArg2 = arg2.slice().sort();

        for (let i = 0; i < sortedArg1.length; i++) {
          if (sortedArg1[i] !== sortedArg2[i]) {
            return false;
          }
        }

        return true;
      } else if (Array.isArray(arg1) || Array.isArray(arg2)) {
        return false;
      } else {
        return arg1 === arg2;
      }
    },
    fieldFilledByCurrentUser(userType) {
      let authDetails = store.getters["auth/getAuthenticationDetails"];
      let authUser = store.getters["auth/getAuthenticatedUser"];
      let passcode = authDetails && authDetails["passcode"];
      if (this.guestRoutes.indexOf(this.$route.name) !== -1 && passcode) {
        if (userType == "RECEIVER" || userType == "SENDER_OR_RECEIVER") {
          return true;
        }
        return false;
      } else if (authUser?.is_contact) {
        if (userType == "RECEIVER" || userType == "SENDER_OR_RECEIVER") {
          return true;
        }
        return false;
      }
      return true;
    },
  },
};
