
<template>
  <div
    class="form-group"
    :style="getElementStyle()"
    v-if="  data.properties.filed_content != 'Hide'"
  >
    <el-row v-if="!data.properties.hideLabel">
      <el-col>
        <span>
          <label
            for="text"
            v-if="hasLabel"
            :style="getLabelStyles"
          >
            {{ data.label }}
          </label>
        </span>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span
          v-if="data.description"
          style="color: #c0c4cc; font-weight: bold"
        >
          <el-tooltip
            :content="data.description"
            placement="top"
            effect="dark"
          >
            <i class="el-icon-info"></i>
          </el-tooltip>
        </span>
      </el-col>
    </el-row>

    <el-row v-if="!isView">
      <el-input
        type="email"
        v-if="!isView"
        :style="getStyle"
        :placeholder="data.placeholder || data.label"
        v-model="form[data.key]"
        :required="data.validations.required"
        @blur="validateEmail"
        :disabled="checkReadonly()"
        :minlength="
          data.properties.minlength_value ? data.properties.minlength_value : 8
        "
        :maxlength="
          data.properties.maxlength_value
            ? data.properties.maxlength_value
            : 256
        "
        :suffix-icon="data.properties.el_icon"
      ></el-input>
    </el-row>

    <el-row align="middle">
      <el-col :span="showLabel
        &&
        !isDefalutPos
        ?
        12
        :
        24">
        <p
          v-if="hasError"
          style="color: red; font-size: 14px"
        >
          {{ errorMsg }}
        </p>
        <p
          v-if="isView"
          :style="getStyle"
        >
          <template v-if="form[data.key]">{{ form[data.key] }}</template>
          <template v-else>-</template>
        </p>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import templateConstants from "../../../constants/lg-en/templates";

export default {
  components: {},
  mixins: [userPermissionsHelper],
  props: ["data", "form", "isView", "hasLabel","fromEntityViews"],
  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px; font-weight: 700`;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "";
    }
  },
  data() {
    return {
      hasError: false,
      errorMsg: "",
      showLabel: true,
      isDefalutPos: true
    };
  },
  methods: {
    checkReadonly() {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        if (this.data.styles.labelStyle == "right") {
          this.isDefalutPos = false;
        }
      }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
      }
      return borderStyle;
    },
    validateEmail() {
      if (templateConstants.emailPattern.test(this.form[this.data.key])) {
        this.errorMsg = "";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Email Format";
        this.hasError = true;
      }
    },
    validatePassword() {
      if (templateConstants.emailPattern.test(this.form[this.data.key])) {
        this.errorMsg = "";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Email Format";
        this.hasError = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>